import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AthenaLoaderComponent } from './components/kyc-lux-loader/kyc-lux-loader.component';
import { AngularMaterialModule } from './angular-material/angular-material.module';
import { TranslocoModule } from '@ngneat/transloco';
import { CustomizedSnackbarComponent } from './components/customized-snackbar/customized-snackbar.component';
import { LegendComponent } from './components/legend/legend.component';
import { PopUpComponent } from './components/pop-up/pop-up.component';
import { HttpClientModule } from '@angular/common/http';
@NgModule({
    declarations: [
        AthenaLoaderComponent,
        CustomizedSnackbarComponent,
        LegendComponent,
        PopUpComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AngularMaterialModule,
        NgOptimizedImage,
        TranslocoModule,
        HttpClientModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AthenaLoaderComponent,
        AngularMaterialModule,
        NgOptimizedImage,
        TranslocoModule,
        LegendComponent,
        HttpClientModule
        
    ]
})
export class SharedModule {
}
