const API_URL = 'https://recette-mmc.by-lios.com/api';
const API_ADMIN_URL = '/api/admin';
export const environment = {
  RECAPTCHA_KEY_URL: '6Lcn9KcZAAAAAH4WSn8-jI1mmqCVkFmo7GC5iI9v',
  OTP_EXPIRATION_TIME: 10,
  production: false,
  EDIT_TIMEOUT: 1500,
  ENDPOINTS: {
    REFRESH_URL:`${API_URL}/refresh`,
    CONTROLS_URL:`${API_URL}/risks`,
    COUNTRIES_URL:`${API_URL}/countries`,
    CLIENTS_URL:`${API_URL}/policies`,
    AUDIT_URL:`${API_URL}/policies`,
    ACTIVITY_AREAS_URL:`${API_URL}/activity-areas`,
    SOCIO_PROF_URL:`${API_URL}/socio-professional-categories`,
  }
};