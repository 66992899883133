import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SimpleDataTableComponent } from './simple-data-table.component';
import { SharedModule } from 'app/shared/shared.module';
import { MatTableModule } from '@angular/material/table';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@ngneat/transloco';
import { MatSortModule } from '@angular/material/sort';




@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MatTableModule,
    MatSortModule,
    TranslocoModule
    
  ],
  exports: [SimpleDataTableComponent],
  declarations: [SimpleDataTableComponent],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'datatable', alias: 'datatable' } }],

})
export class SimpleDataTableModule { }
