<div class="popup-container">
  <div *ngIf="isIterable">
    <p *ngFor="let item of msg">{{ item }}</p>
  </div>
  <div *ngIf="!isIterable" class="msg">
   
    <span style="margin-left: 10px;">{{ msg }}</span>
  </div>

  <div *ngIf="(product && product.length > 0)">
    <div class="negative-product-list" *ngFor="let product of product; let i=index">
      <span>- {{product}}</span>
    </div>
  </div>
  <div class="button-div">
    <button mat-button *ngFor="let option of options" (click)="action(option.value)">{{option.text}}</button>
  </div>
</div>