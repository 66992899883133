import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/contracts'
    { path: '', pathMatch: 'full', redirectTo: 'contracts' },

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    // { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'contracts' },





    // Admin routes
    {
        path: '',
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        data: { roles: ['user'] },
        children: [
            // { path: 'example', loadChildren: () => import('app/modules/admin/example/example.routes') },
            {
                path: 'contracts',
                loadChildren: () => import('app/modules/pages/clients/clients.module').then(m => m.ClientsModule),
            },
            {
                path: 'controls-rules',
                loadChildren: () => import('app/modules/pages/controls-rules/controls-rules..module').then(m => m.BallotsModule),
            },
            {
                path: 'countries',
                loadChildren: () => import('app/modules/pages/countries/countries.module').then(m => m.ConnectedUsersModule),
              },
              {
                path: 'activity-areas',
                loadChildren: () => import('app/modules/pages/activity-areas/activity-areas.module').then(m => m.ActivityAreasModule),
              },
              {
                path: 'socio-professional-categories',
                loadChildren: () => import('app/modules/pages/cat-socio-prof/cat-socio-prof.module').then(m => m.CatSocioProfModule),
              },
        ]
    }
];
