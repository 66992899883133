import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { translate } from '@ngneat/transloco';
import { CustomizedSnackbarComponent } from 'app/shared/components/customized-snackbar/customized-snackbar.component';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    
    constructor(
        private _snackBar: MatSnackBar,
        private httpClient: HttpClient
    ) { }
    successMessage = 'success-message-snackbar';
    errorMessage = 'error-snackbar';
    infoMessage = 'info-message-snackbar';

    showSnackBar( message: string, snackBarType: string, disableTranslate?: boolean ): void {

        this._snackBar.openFromComponent(CustomizedSnackbarComponent, {

            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: [snackBarType], // .success-message-snackbar .error-snackbar .info-message-snackbar
            duration: 5000,

            data: {
                snackBarType,
                msg: disableTranslate ? message : translate(message),
            }
        });
    }


}
