import { Component, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'app-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss']
})
export class LegendComponent implements OnInit {
  @ViewChild('legendContent') legendContent: TemplateRef<any>;
  constructor(public viewContainerRef: ViewContainerRef) { }

  ngOnInit() {
  }

}
