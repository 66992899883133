import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {
  clientsUrl = environment.ENDPOINTS.CLIENTS_URL;
  refreshUrl = environment.ENDPOINTS.REFRESH_URL;
constructor(
  private httpClient: HttpClient,
) { }
getAllClients(page: number, size: number, sort?: any, direction?: any, search?: any,name?:any,firstName?:any,partyId?:number,riskCodes?:[]){
  const searchParam = search ? `&policyExt=${search}` : '';
   const id = partyId?`&partyId=${partyId}`:'';
   const lastName = name?`&name=${name}`:'';
   const fName = firstName?`&firstName=${firstName}`:'';
   const codes = riskCodes && riskCodes.length>0 ? `&riskCodes=${riskCodes}`:''
  const req = `${this.clientsUrl}` +
    `?page=${page}&size=${size}&sort=${sort},${direction}`+searchParam+id +lastName+fName+codes;
  return this.httpClient.get<any[]>(req, { observe: 'response' });
}

getContractById(id:number){
  const req = `${this.clientsUrl}/${id}`;
  return this.httpClient.get(req,{observe:'response'})
}

updateBulk(payload: any,id) {     
    const req = `${this.clientsUrl}/${id}/policy-risks/bulk-update`;
  return this.httpClient.post(req, payload);
}

refresh(){
  const req = `${this.refreshUrl}`;
  return this.httpClient.post(req,{});
}

  
 
}
