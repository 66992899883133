<!-- <div class="page-container" fxLayout="column" fxLayoutGap="20px">
  <div style="display: flex;height: 100%;">


  </div>
</div> -->


<div class="flex flex-col h-full w-full min-w-0 sm:absolute sm:inset-0 sm:overflow-hidden">

  <mat-drawer-container class="flex-auto sm:h-full">

      <!-- Drawer -->
      <mat-drawer
          class="sm:w-96 dark:bg-gray-900"
          [autoFocus]="false"
          [mode]="drawerMode"
          [opened]="drawerOpened"
          #drawer>
          <!-- Header -->
          <!-- <div class="flex items-center justify-between m-8 mr-6 sm:my-10"> -->
              <!-- Close button -->
              <!-- <div class="lg:hidden">
                  <button
                      mat-icon-button
                      (click)="drawer.close()">
                      <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                  </button>
              </div>
          </div> -->
          <!-- Panel links -->
          <div style="display: flex;height: 100%;flex-direction: column;">

            <div fxLayout="column"  class="card">
              <mat-form-field style="margin-bottom: 20px;"
              class="fuse-mat-dense fuse-mat-rounded min-w-64"
              [subscriptSizing]="'dynamic'">
              <mat-icon
                  class="icon-size-5"
                  matPrefix
                  [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
                  <input
                  matInput
                  placeholder="Chercher"
                  [name]="searchText"
                  [(ngModel)]="searchText"
                  (ngModelChange)="applySearch()"
                  (input)="handleSearchKeyPress()"
                />
             </mat-form-field>
           <div style="display: flex;flex-direction: row-reverse;position: relative;bottom: 8px;margin-top: 15px;">
            <mat-slide-toggle [(ngModel)]="toggleStatus" (change)="toggleCheckboxStatus()"[color]="'primary'">
              Cocher tout
            </mat-slide-toggle>
           </div>
            <mat-accordion  class="example-headers-align" multi>
              <mat-expansion-panel *ngFor="let item of filteredContents; let i = index"  [expanded]="isAccordionOpen(i)">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{ i + 1 }}-{{ item.title }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ul style="position: relative; width: 100%; max-width: 100%; overflow: auto;">
                  <li style="width: 100%; list-style: none; margin: 0 auto;" *ngFor="let subtask of item.content.subtasks">
                    <mat-checkbox
                      [(ngModel)]="subtask.completed"
                      [color]="subtask.color"
                      *ngIf="searchText === '' || subtask.isMatched"
                      (change)="updateSelectedRiskCodes(subtask.riskCode,subtask.completed)"
                      [matTooltip]="subtask.name"
                      class="custom-checkbox"
                    >
                    {{subtask.riskCode}}-{{ subtask.name }}
                    </mat-checkbox>
                  </li>
                </ul>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          </div>
      </mat-drawer>

      <!-- Drawer content -->
      <mat-drawer-content class="flex flex-col">

          <!-- Main -->
          <div class="flex-auto">

              <!-- Load settings panel -->
              <div class="h-full">
                <div style="height: 100%;position: relative;width: 100%;padding-left: 2vmin;">
  
                
                  <mat-card class="card h-full">
              <!-- Drawer toggle -->
              <button class="lg:hidden -ml-2" mat-icon-button (click)="drawer.toggle()">
                  <mat-icon >menu</mat-icon>
              </button>
                    <div class="flex flex-col sm:flex-row items-center justify-between w-full max-w-xs sm:max-w-none">

                      <mat-form-field
                          class="w-full sm:w-80 mt-4 sm:mt-0 sm:ml-4"
                          [subscriptSizing]="'dynamic'">
                          <button mat-icon-button matPrefix *ngIf="searchControl.value" (click)="clearSearch(searchControl)">
                            <mat-icon>cancel</mat-icon>
                          </button>
                          <input matInput placeholder="contrats" id="search" [formControl]="searchControl"
                          (keydown.enter)="searchFilters()"  (blur)="onInputBlur(searchControl)">
                      </mat-form-field>
                      <mat-form-field
                      class="w-full sm:w-80 mt-4 sm:mt-0 sm:ml-4"
                      [subscriptSizing]="'dynamic'">
                      <button mat-icon-button matPrefix *ngIf="searchTiersControl.value" (click)="clearSearch(searchTiersControl)">
                        <mat-icon>cancel</mat-icon>
                      </button>
                      <input matInput placeholder="tiers" id="search" [formControl]="searchTiersControl"
                      (keydown.enter)="searchFilters()" (blur)="onInputBlur(searchTiersControl)">
                  </mat-form-field>

                  <button 
                  class="ml-4 mr-4"
                  mat-flat-button
                  [color]="'primary'"
                  (click)="searchFilters()">
                  <mat-icon >search</mat-icon>
                  <span class="ml-2 mr-1">Chercher</span>
                  </button>

                  </div>

                    <app-simple-data-table [tableData]="clients" [tableColumns]="clientsTableColumns" [isPageable]="true" [size]="pageSize"
                    [totalItems]="totalItems" [paginationSizes]="[10]" [defaultPageSize]="1"  (sort)="sortData($event)"[pageIndex]="pageIndex"
                      (rowAction)="actionOnTable($event)" (changPager)="changePager($event)" [componentName]="'Contrats'" [rowBalotAction]="'history'">
                    </app-simple-data-table>

                    
                    <app-legend class="mt-4 ml-4"></app-legend>
                  </mat-card>
                 
                </div>
              </div>

          </div>

      </mat-drawer-content>

  </mat-drawer-container>

</div>
<fuse-drawer 
class="w-screen min-w-screen sm:w-200 sm:min-w-200 z-999"
fixed
[mode]="'over'"
[position]="'right'"
#rightDrawer
[name]="'rightDrawer'"
[opened]="historyDrawerOpened">
<div *ngIf="selectedPolicy" class="flex flex-col w-full overflow-auto bg-card">
  <activity  [id]="selectedPolicy"></activity>
</div>
</fuse-drawer>