import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { StorageUtilsService } from 'app/services/storage-utils/storage-utils.service';
import { CommonService } from 'app/services/common/common.service';
import { StorageUtils } from '../storage/storage-utils';
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(
        private _authService: AuthService,
        private _router: Router,
        private _storageUtilsService: StorageUtilsService,
        private _commonService: CommonService,
    ) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let modified = request;
        if (StorageUtils.getAccessToken()) {  
           modified = request.clone({ setHeaders: { Authorization: 'Bearer ' + StorageUtils.getAccessToken() } });
        }
        return next.handle(modified).pipe(
            retry(0),
            catchError((error: HttpErrorResponse) => {
                if (!(error.error instanceof ErrorEvent)) {

                    switch (error.status) {
                        case 400:
                                this.showErrorSnackbar({ errorMessage: 'Erreur 400, Mauvaise requête' })
                            break;

                        case 401:
                            this._authService.login();
                            this._router.navigate(['']);
                                // this.showErrorSnackbar({ errorMessage: 'Erreur 401, non autorisé, contacter le support client' })

                            break;
                        case 403:
                                this.showErrorSnackbar({ errorMessage: 'Erreur 403, non autorisé, contacter le support client' })
                            break;
                        case 404:
                                this.showErrorSnackbar({ errorMessage: 'Erreur 404, Page introuvable!' })
                            break;

                        case 500:
                            this.showErrorSnackbar({ errorMessage: 'Erreur 500, Serveur' })
                            break;
                        case 504:
                                this.showErrorSnackbar({ errorMessage: 'Erreur 504, Timeout, Réessayer dans 30 secondes.' })
                            break;
                        default:
                            break;
                    }
                }
                return throwError(error);
            })
        );
    }

    showErrorSnackbar({ errorMessage }: { errorMessage: string }): void {
        this._commonService.showSnackBar(  errorMessage, this._commonService.errorMessage, );
    }
}
