import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-kyc-lux-loader-loader',
    templateUrl: './kyc-lux-loader.component.html',
    styleUrls: ['./kyc-lux-loader.component.scss']
})
export class AthenaLoaderComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
