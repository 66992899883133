import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { TableColumn } from 'app/shared/components/simple-data-table/table-columns';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { contents } from "./data"
import { ClientsService } from 'app/services/clients.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { MatDrawer } from '@angular/material/sidenav';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { Subject, takeUntil } from 'rxjs';


@Component({
  selector: 'app-view-all-clients',
  templateUrl: './view-all-clients.component.html',
  styleUrls: ['./view-all-clients.component.scss'],
  animations: [
    trigger('buttonAnimation', [
      state('inactive', style({
        transform: 'scale(1)'
      })),
      state('active', style({
        transform: 'scale(1.1)'
      })),
      transition('inactive => active', animate('300ms ease-in')),
      transition('active => inactive', animate('300ms ease-out'))
    ])
  ],
})
export class ViewAllClientsComponent implements OnInit, OnDestroy {
  searchControl: FormControl = new FormControl(null);
  searchTiersControl: FormControl = new FormControl(null)
  searchRiskControl: FormControl = new FormControl(null);
  displayMode: string = 'default';
  @ViewChild(MatAccordion) accordion: MatAccordion;
  searchTimeOut: any;
  multi = true
  clearSearchTimeOut: any
  direction = 'desc';
  selectedRiskCodes: any[] = []
  searchValue: any;
  checkAll: boolean = false
  pageIndex: any = 0;
  totalItems: any;
  pageSize: any = 10;
  sort = '';
  timer: any;
  clients = [];
  data: any[] = []
  filteredContents: any[]
  searchText: string = '';
  clientsTableColumns: TableColumn[];
  toggleStatus: boolean = false;
  @ViewChildren("panel") panel: MatExpansionPanel;
  accordionOpenState: boolean[] = [];
  private overlayRef: OverlayRef | null = null;
  allComplete: boolean = false;
  buttonState = 'inactive';

  @ViewChild('drawer') drawer: MatDrawer;
  drawerMode: 'over' | 'side' = 'side';
  drawerOpened: boolean = true;
  historyDrawerOpened: boolean = false;
  selectedPolicy: any;
  @ViewChild('rightDrawer') rightDrawer: MatDrawer;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private clientsService: ClientsService,
    private router: Router,
    private overlay: Overlay,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.pageIndex = params.page?params.page:0;
      this.pageSize = params.size?params.size:10;
      this.sort = params.sort?params.sort:'desc';
      this.direction = params.direction?params.direction:''
    });
  }

  toggleDrawerOpen(id): void {
    this.selectedPolicy = null;
    setTimeout(() => {
      this.selectedPolicy = id
    }, 1000);
    this.rightDrawer.toggle()



  }

  ngOnInit() {
    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {

        // Set the drawerMode and drawerOpened
        if (matchingAliases.includes('lg')) {
          this.drawerMode = 'side';
          this.drawerOpened = true;
        }
        else {
          this.drawerMode = 'over';
          this.drawerOpened = false;
        }

        // Mark for check
        this._changeDetectorRef.markForCheck();
      });
    this.data = contents
    this.initTable();
    this.filteredContents = contents
  }
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
  animateButton() {
    this.buttonState = (this.buttonState === 'inactive') ? 'active' : 'inactive';
  }
  initTable() {
    this.initializeColumns();
  }

  updateSelectedRiskCodes(riskCode: string, checked?: boolean) {

    if (this.checkAll && checked == false) {
      this.toggleStatus = false
      //  this.toggleCheckboxStatus()
    }

    if (this.selectedRiskCodes.includes(riskCode)) {
      this.selectedRiskCodes = this.selectedRiskCodes.filter(code => code !== riskCode);
    } else {
      this.selectedRiskCodes.push(riskCode);
    }


  }

  toggleCheckboxStatus() {
    this.checkAll = !this.checkAll
    for (const item of this.filteredContents) {
      for (const subtask of item.content.subtasks) {
        subtask.completed = this.toggleStatus;
        if (this.toggleStatus) {
          this.updateSelectedRiskCodes(subtask.riskCode);
        }
      }
    }
    if (!this.toggleStatus) {
      this.selectedRiskCodes = [];
    }
    this.searchFilters();
  }
  getAllClients(pageIndex, pageSize, sort, dir, search?, name?, firstName?, partyId?, riskCodes?) {
    this.clientsService.getAllClients(pageIndex, pageSize, sort, dir, search, name, firstName, partyId, riskCodes).subscribe((res: any) => {
      this.totalItems = res.headers.get('X-Total-Count') !== null ? res.headers.get('X-Total-Count') : 0;
      res.body.map((item) => {
        item.risky = item.policyRisks.length > 0 ? false : true
        item.group = item.policyRisks.length > 0 ? item.policyRisks.map(i => i.riskId).join(', ') : '/'
        item.subscribersGroup = item.subscribers?.map(i => i.name + " " + i.firstName).join(', ')
        item.insuredsGroup = item.insureds.map(i => i.name + " " + i.firstName).join(', ')
        item.effectDate = new Date(item.effectDate).toLocaleDateString("fr")

        item.actions = [{
          label: 'Historique',
          icone: '',
          onClick: (id) => (this.toggleDrawerOpen(id)),
        },
        ]
      })
      this.clients = res.body
    })
  }

  searchChanged(value) {
    clearTimeout(this.searchTimeOut);
    this.searchTimeOut = setTimeout(() => {
      this.searchValue = value;
      if (this.selectedRiskCodes.length > 0) {
        this.getAllClients(this.pageIndex, this.pageSize, this.sort, this.direction, this.searchValue, null, null, null, this.selectedRiskCodes);
      } else {
        this.getAllClients(this.pageIndex, this.pageSize, this.sort, this.direction, this.searchValue)
      }
    }, 500);

  }

  searchPartyChanged(value) {
    clearTimeout(this.searchTimeOut);
    this.searchTimeOut = setTimeout(() => {
      const inputValue = value.trim();
      const isNumericInput = !isNaN(inputValue);
      const partyId = isNumericInput ? parseInt(inputValue, 10) : null;
      const name = isNumericInput ? null : inputValue;
      if (this.selectedRiskCodes.length > 0) {
        this.getAllClients(this.pageIndex, this.pageSize, this.sort, this.direction, null, name, null, partyId, this.selectedRiskCodes);
      } else {
        this.getAllClients(this.pageIndex, this.pageSize, this.sort, this.direction, null, name, null, partyId, null);
      }


    }, 500);
  }
  changePager(event) {
    const inputValue = this.searchTiersControl.value;
    const isNumericInput = !isNaN(inputValue);
    const partyId = isNumericInput ? parseInt(inputValue, 10) : null;
    const name = isNumericInput ? null : inputValue;
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getAllClients(this.pageIndex, this.pageSize, this.sort, this.direction, this.searchControl.value, name, null, partyId, this.selectedRiskCodes);
  }
  clearSearch(control) {
    control.setValue('');
    clearTimeout(this.searchTimeOut);
    clearTimeout(this.clearSearchTimeOut);
    this.getAllClients(this.pageIndex, this.pageSize, this.sort, this.direction);
  }
  onInputBlur(control) {
    const inputValue = control.value;
    if (inputValue === '') {
      clearTimeout(this.searchTimeOut);
      clearTimeout(this.clearSearchTimeOut);
      this.getAllClients(this.pageIndex, this.pageSize, this.sort, this.direction);
    }
  }
  searchFilters() {
    if (this.searchControl.value && !this.searchTiersControl.value) {
      this.searchChanged(this.searchControl.value)
      this.clients = []
      this.pageIndex = 0
    }
    if (this.searchTiersControl.value && !this.searchControl.value) {
      this.searchPartyChanged(this.searchTiersControl.value)
      this.clients = []
      this.pageIndex = 0
    }
    if (this.selectedRiskCodes.length > 0 && !this.searchControl.value && !this.searchTiersControl.value) {
      this.getAllClients(this.pageIndex, this.pageSize, this.sort, this.direction, null, null, null, null, this.selectedRiskCodes)
      this.clients = []
      this.pageIndex = 0
    }
    if (this.searchTiersControl.value && this.searchControl.value && this.selectedRiskCodes.length == 0) {
      const isNumericInput = !isNaN(this.searchTiersControl.value);
      const partyId = isNumericInput ? parseInt(this.searchTiersControl.value, 10) : null;
      const name = isNumericInput ? null : this.searchTiersControl.value;
      this.getAllClients(this.pageIndex, this.pageSize, this.sort, this.direction, this.searchControl.value, name, null, partyId);
      this.clients = []
      this.pageIndex = 0
    }
    if (this.searchTiersControl.value && this.searchControl.value && this.selectedRiskCodes.length > 0) {
      const isNumericInput = !isNaN(this.searchTiersControl.value);
      const partyId = isNumericInput ? parseInt(this.searchTiersControl.value, 10) : null;
      const name = isNumericInput ? null : this.searchTiersControl.value;
      this.getAllClients(this.pageIndex, this.pageSize, this.sort, this.direction, this.searchControl.value, name, null, partyId, this.selectedRiskCodes);
      this.clients = []
      this.pageIndex = 0
    }
    if (this.selectedRiskCodes.length == 0 && !this.searchControl.value && !this.searchTiersControl.value) {
      this.getAllClients(this.pageIndex, this.pageSize, this.sort, this.direction)
      this.clients = []
      this.pageIndex = 0
    }
  }
  initializeColumns(): void {
    this.clientsTableColumns = [
      {

        name: 'clients.columns.id',
        dataKey: 'policyExt',
        dataKeyDto: 'policyExt',
        isSortable: false,
        minWidth: 10,
      },

      {
        name: 'clients.columns.surveillance',
        dataKey: 'surveillanceLevel',
        dataKeyDto: 'surveillanceLevel',
        position: 'left',
        isSortable: false,
        minWidth: 10
      },

      {
        name: 'clients.columns.score',
        dataKey: 'score',
        dataKeyDto: 'score',
        position: 'right',
        isSortable: true,
        minWidth: 10,
      },
      {
        name: 'clients.columns.subscribers',
        dataKey: 'subscribersGroup',
        dataKeyDto: 'subscribers',
        position: 'left',
        isSortable: false,
        minWidth: 20
      },
      {
        name: 'clients.columns.insured',
        dataKey: 'insuredsGroup',
        dataKeyDto: 'insureds',
        position: 'left',
        isSortable: false,
        minWidth: 20
      },
      {
        name: 'clients.columns.date',
        dataKey: 'effectDate',
        dataKeyDto: 'effectDate',
        position: 'left',
        isSortable: true,
        minWidth: 15
      },
    ];
    this.getAllClients(this.pageIndex, this.pageSize, this.sort, this.direction);
  }
  sortData(sort: Sort) {
    this.direction = sort.direction;
    this.sort = sort.active;
    this.pageIndex = 0;
    this.pageSize = 10;
    this.getAllClients(this.pageIndex, this.pageSize, this.sort, this.direction);
  }


  actionOnTable(event) {
    if (event.actionType === 'rowClick') {
      this.router.navigate(['contracts', event.row.id, 'details'], { queryParams: { page: this.pageIndex, size: this.pageSize, sort: this.sort, direction: this.direction } });
    }
  }


  applySearch(): void {
    this.filteredContents = this.data.filter((item) => {
      item.content.subtasks.forEach((subtask) => {
        subtask.isMatched = subtask.name.toLowerCase().includes(this.searchText.toLowerCase());
      });

      return item.content.subtasks.some((subtask) => subtask.isMatched);
    });
    if (this.searchText === '') {
      this.closeAllAccordionPanels();
    }
  }
  closeAllAccordionPanels() {
    this.accordionOpenState = this.filteredContents.map(() => false);
  }
  isAccordionOpen(index: number): boolean {
    const item = this.filteredContents[index];
    if (this.searchText && item.content.subtasks.length > 0) {
      return item.content.subtasks.some((subtask) =>
        subtask.name.toLowerCase().includes(this.searchText.toLowerCase())
      );
    }
    return false;
  }
  handleSearchEnter() {
    this.applySearch();

    const matchedItems = this.filteredContents.filter(item =>
      item.content.subtasks.some(subtask =>
        subtask.name.toLowerCase().includes(this.searchText.toLowerCase())
      )
    );

    matchedItems.forEach(item => {
      const index = this.filteredContents.indexOf(item);
      this.setAccordionOpenState(index, true);
    });
  }
  setAccordionOpenState(index: number, expanded: boolean) {
    this.accordionOpenState[index] = expanded;
  }

  handleSearchKeyPress() {
    this.applySearch();

    const matchedIndices = [];

    this.filteredContents.forEach((item, index) => {
      const isMatched = item.content.subtasks.some(
        (subtask) =>
          subtask.name.toLowerCase().includes(this.searchText.toLowerCase())
      );

      if (isMatched) {
        matchedIndices.push(index);
      }
    });

    this.filteredContents.forEach((item, index) => {
      this.setAccordionOpenState(index, matchedIndices.includes(index));
    });

    if (this.searchText === '') {
      this.closeAllAccordionPanels();
    }
  }

  goToHistory(id) {
    this.router.navigate(['contracts', id, 'audit']);

  }

}
