import {  Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute, } from '@angular/router';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { ActivitiesService } from './activities.service';
import { Activity } from './activities.types';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';

@Component({
    selector: 'activity',
    templateUrl: './activities.component.html',
    providers: [{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'datatable', alias: 'datatable' } }],
})
export class ActivitiesComponent implements OnInit {
    activities:any;
    displayedColumns: string[] = ['code', 'label', 'previous', 'current'];
    partyDisplayedColumns: string[] = ['name','previous', 'current'];
    @Input() id: string;
    /**
     * Constructor
     */
    constructor(public _activityService: ActivitiesService,
        private activatedRoute: ActivatedRoute,
        private translocoService: TranslocoService,
        @Inject(TRANSLOCO_SCOPE) private scope) {
        // this.activatedRoute.params.subscribe(params => {
        //     const id = params['id'];
        //     this.getAllAudit(id);
        //   })
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.getAllAudit(this.id);
        // Get the activities
        // this.activities$ = this._activityService.activities;
        // console.log(this.activities$);
        
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Returns whether the given dates are different days
     *
     * @param current
     * @param compare
     */
    isSameDay(current: string, compare: string): boolean {
        return DateTime.fromISO(current).hasSame(DateTime.fromISO(compare), 'day');
    }

    /**
     * Get the relative format of the given date
     *
     * @param date
     */
    getRelativeFormat(date: string): string {
        return DateTime.fromISO(date).toRelativeCalendar();
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.revisionDate || index;
    }

    getAllAudit(id) {
        this._activityService.getAllAudit(id).subscribe(res => {
            
            const data:any = res;
            data.map((item) => {
                if (item.risks) {
                    item.risks = item.risks.map(i => ({
                        ...i,
                        labelSub : i.label.length>30? i.label.substr(0,30)+" ...":i.label,
                        previous : i.score?.previous>=0?i.score?.previous:i.score,
                        current : i.score?.current>=0?i.score?.current:i.score,
                    })).sort(function(a, b) {
                        const codeA : number= +a.code.slice(1);
                        const codeB : number= +b.code.slice(1);
                        if (codeA > codeB) {
                          return 1;
                        }
                        if (codeA < codeB) {
                          return -1;
                        }
                        // names must be equal
                        return 0;
                      });
                }
                if (item.parties) {
                    item.parties = item.parties.map(
                        part=>{
                            const elemnt:any=[];
                            for (const [key, val] of Object.entries(part) as any) {
                                elemnt.push({
                                    name: key,
                                    current:val .current,
                                    previous:val.previous
                                })
                            }
                            return elemnt;
                        }
                    );
                }
            })
            this.activities = data;
            
        })
    }
}
