<div class="relative flex flex-col flex-auto min-w-0 overflow-hidden" *transloco="let datatable; read: 'datatable'">
  <!-- https://run.mocky.io/v3/cb35be08-2e81-4d15-afb1-4e1700f44cbe -->
  <!-- Main -->
  <div class="flex flex-col flex-auto px-6 py-10 sm:px-16 sm:pt-18 sm:pb-20">
    <!-- Activity feed -->
    <div class="w-full max-w-3xl">
      <!-- Title -->
      <div class="text-4xl font-extrabold tracking-tight leading-none">Historique</div>
      <div class="mt-1.5 text-lg text-secondary"></div>

      <ng-container *ngIf="activities; else loading">
        <ng-container *ngIf="activities.length">
          <div class="mt-8">
            <ol>
              <!-- Activities -->
              <ng-container *ngFor="let activity of activities">
                <!-- Date separator -->
                <ng-container>
                  <li class="relative flex py-7">
                    <div class="relative py-2 px-8 text-md font-medium leading-5 rounded-full bg-primary text-on-primary">
                      <!-- {{getRelativeFormat(activity.revisionDate) | titlecase}} -->
                      {{ activity.revisionDate | date : "dd MM YYYY , HH:MM " }}
                    </div>
                  </li>
                </ng-container>

                <!-- Score -->
                <li class="relative flex py-7" *ngIf="activity.score">
                  <!-- Line -->
                  <ng-container>
                    <div class="absolute top-7 left-5 w-0.5 h-full -ml-px bg-gray-300 dark:bg-gray-600"></div>
                  </ng-container>

                  <div class="relative flex flex-auto">
                    <!-- Icon -->
                    <ng-container>
                      <div class="flex shrink-0 items-center justify-center w-10 h-10 mr-4 rounded-full bg-gray-400">
                        <mat-icon class="icon-size-5 text-white" [svgIcon]="'heroicons_solid:star'"> </mat-icon>
                      </div>
                    </ng-container>

                    <!-- Content -->
                    <div class="flex flex-col flex-auto items-start">
                      <!-- Description -->
                      <ng-container>
                        <div class="text-2xl font-extrabold">{{ "Score" }}</div>
                      </ng-container>
                      <!-- Extra content -->
                      <ng-container>
                        <div class="mt-4 py-4 px-5 rounded-lg flex flex-col">
                          <span class="text-1xl font-extrabold"> {{ datatable("previous") }} : {{ activity.score.previous }}</span>
                          <span class="text-1xl font-extrabold">{{ datatable("current") }} :{{ activity.score.current }}</span>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </li>

                <li class="relative flex py-7" *ngIf="activity.surveillanceLevel">
                  <!-- Line -->
                  <ng-container>
                    <div class="absolute top-7 left-5 w-0.5 h-full -ml-px bg-gray-300 dark:bg-gray-600"></div>
                  </ng-container>

                  <div class="relative flex flex-auto">
                    <ng-container>
                      <div class="flex shrink-0 items-center justify-center w-10 h-10 mr-4 rounded-full bg-gray-400">
                        <mat-icon class="icon-size-5 text-white" [svgIcon]="'heroicons_solid:star'"> </mat-icon>
                      </div>
                    </ng-container>

                    <div class="flex flex-col flex-auto items-start">
                      <ng-container>
                        <div class="text-2xl font-extrabold">{{ datatable("surveillanceLevel") }}</div>
                      </ng-container>

                      <ng-container>
                        <div class="mt-4 py-4 px-5 rounded-lg flex flex-col">
                          <span class="text-1xl font-extrabold">
                            {{ datatable("previous") }} : {{ activity.surveillanceLevel.previous }}</span
                          >
                          <span class="text-1xl font-extrabold">{{ datatable("current") }}:{{ activity.surveillanceLevel.current }}</span>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </li>

                <li class="relative flex py-7" *ngIf="activity.netAssetValue">
                  <!-- Line -->
                  <ng-container>
                    <div class="absolute top-7 left-5 w-0.5 h-full -ml-px bg-gray-300 dark:bg-gray-600"></div>
                  </ng-container>

                  <div class="relative flex flex-auto">
                    <!-- Icon -->
                    <ng-container>
                      <div class="flex shrink-0 items-center justify-center w-10 h-10 mr-4 rounded-full bg-gray-400">
                        <mat-icon class="icon-size-5 text-white" [svgIcon]="'heroicons_solid:star'"> </mat-icon>
                      </div>
                    </ng-container>

                    <!-- Content -->
                    <div class="flex flex-col flex-auto items-start">
                      <!-- Description -->
                      <ng-container>
                        <div class="text-2xl font-extrabold">{{ "Provision Mathématique" }}</div>
                      </ng-container>
                      <!-- Extra content -->
                      <ng-container>
                        <div class="mt-4 py-4 px-5 rounded-lg flex flex-col">
                          <p class="text-1xl font-extrabold">
                            {{ datatable("previous") }} : {{ activity.netAssetValue.previous | currency : "EUR" }}
                          </p>
                          <p class="text-1xl font-extrabold">
                            {{ datatable("current") }} :{{ activity.netAssetValue.current | currency : "EUR" }}
                          </p>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </li>

                <li class="relative flex py-7" *ngIf="activity.risks">
                  <!-- Line -->
                  <ng-container>
                    <div class="absolute top-7 left-5 w-0.5 h-full -ml-px bg-gray-300 dark:bg-gray-600"></div>
                  </ng-container>

                  <div class="relative flex flex-auto">
                    <ng-container>
                      <div class="flex shrink-0 items-center justify-center w-10 h-10 mr-4 rounded-full bg-gray-400">
                        <mat-icon class="icon-size-5 text-white" [svgIcon]="'heroicons_solid:star'"> </mat-icon>
                      </div>
                    </ng-container>

                    <div class="flex flex-col flex-auto items-start">
                      <ng-container>
                        <div class="text-2xl font-extrabold">{{ "Risques modifiés" }}</div>
                      </ng-container>

                      <ng-container>
                        <div class="mt-4 py-4 px-5 rounded-lg">
                          <table style="width: 100%" mat-table [dataSource]="activity.risks" class="mat-elevation-z8">
                            <!--- Note that these columns can be defined in any order.
                                                              The actual rendered columns are set as a property on the row definition" -->

                            <!-- Position Column -->
                            <ng-container matColumnDef="code">
                              <th mat-header-cell *matHeaderCellDef>code</th>
                              <td mat-cell *matCellDef="let element"[ngClass]="{ 'text-blue-500': element.changed }">{{ element.code.toLowerCase() }}</td>
                            </ng-container>

                            <!-- Name Column -->
                            <ng-container matColumnDef="label">
                              <th mat-header-cell *matHeaderCellDef>label</th>
                              <td mat-cell *matCellDef="let element" [ngClass]="{ 'text-blue-500': element.changed }" class=""[matTooltip]="element.label">{{ element.labelSub }}</td>
                            </ng-container>

                            <!-- Weight Column -->
                            <ng-container matColumnDef="previous">
                              <th mat-header-cell *matHeaderCellDef>{{ datatable("previous") }}</th>
                              <td mat-cell *matCellDef="let element" [ngClass]="{ 'text-blue-500': element.changed }">{{ element.previous}}</td>
                            </ng-container>

                            <!-- Symbol Column -->
                            <ng-container matColumnDef="current">
                              <th mat-header-cell *matHeaderCellDef>{{ datatable("current") }}</th>
                              <td mat-cell *matCellDef="let element" [ngClass]="{ 'text-blue-500': element.changed }">{{ element.current}}</td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr
                              mat-row
                              *matRowDef="let row; columns: displayedColumns"
                              [ngClass]="{ 'text-blue-500': row.changed}"
                            ></tr>
                          </table>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </li>

                <li class="relative flex py-7" *ngIf="activity.parties">
                  <!-- Line -->
                  <ng-container>
                    <div class="absolute top-7 left-5 w-0.5 h-full -ml-px bg-gray-300 dark:bg-gray-600"></div>
                  </ng-container>

                  <div class="relative flex flex-auto">
                    <ng-container>
                      <div class="flex shrink-0 items-center justify-center w-10 h-10 mr-4 rounded-full bg-gray-400">
                        <mat-icon class="icon-size-5 text-white" [svgIcon]="'heroicons_solid:star'"> </mat-icon>
                      </div>
                    </ng-container>

                    <div class="flex flex-col flex-auto items-start">
                      <ng-container>
                        <div class="text-2xl font-extrabold">{{ "Parties" }}</div>
                      </ng-container>

                      <ng-container>
                        <div *ngFor="let partie of activity.parties" class="mt-4 py-4 px-5 rounded-lg">
                          <table style="width: 100%" mat-table [dataSource]="partie" class="mat-elevation-z8">
                            <!--- Note that these columns can be defined in any order.
                                                              The actual rendered columns are set as a property on the row definition" -->

                            <ng-container matColumnDef="name">
                              <th mat-header-cell *matHeaderCellDef>champ</th>
                              <td mat-cell *matCellDef="let element">{{ datatable(element.name) }}</td>
                            </ng-container>

                            <!-- Weight Column -->
                            <ng-container matColumnDef="previous">
                              <th mat-header-cell *matHeaderCellDef>{{ datatable("previous") }}</th>
                              <td mat-cell *matCellDef="let element">{{ element.previous }}</td>
                            </ng-container>

                            <!-- Symbol Column -->
                            <ng-container matColumnDef="current">
                              <th mat-header-cell *matHeaderCellDef>{{ datatable("current") }}</th>
                              <td mat-cell *matCellDef="let element">{{ element.current }}</td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="partyDisplayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: partyDisplayedColumns"></tr>
                          </table>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </li>
              </ng-container>
            </ol>
          </div>
        </ng-container>
      </ng-container>

      <!-- Loading template -->
      <ng-template #loading> Chargement... </ng-template>

      <!-- No Activity template -->
      <ng-template #noActivity> Pas d'historique pour le moment... </ng-template>
    </div>
  </div>
</div>
