import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from "app/services/common/common.service"
import { ClientsService } from 'app/services/clients.service';
import { TabComponent } from '../tab/tab.component';
import { PopUpComponent } from 'app/shared/components/pop-up/pop-up.component';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { translate } from '@ngneat/transloco';


@Component({
  selector: 'app-clientsDetails',
  templateUrl: './clientsDetails.component.html',
  styleUrls: ['./clientsDetails.component.scss']
})
export class ClientsDetailsComponent implements OnInit {
  @ViewChild('drawer') drawer: MatDrawer;
  drawerMode: 'over' | 'side' = 'side';
  drawerOpened: boolean = true;
  selectedPanel: any = { id: '0', label: 'Risques liés au type de contrat / produit', component: TabComponent, min: 0, max: 1 };
  @ViewChild(MatAccordion) accordion: MatAccordion;
  tabs: string[] = ['sites', 'containers', 'trucks', 'cylinders', 'keys', 'reda', 'data', 'miaou', 'divers'];
  rowTabs = [
    { id: '0', label: 'Risques liés au type de contrat / produit', component: TabComponent, min: 0, max: 1 },
    { id: '1', label: 'Les parties au contrat', component: TabComponent, min: 2, max: 19 },
    { id: '2', label: 'La / les primes', component: TabComponent, min: 20, max: 27 },
    { id: '3', label: 'La clause bénéficiaire et les autres modalités du contrat', component: TabComponent, min: 28, max: 34 },
    { id: '4', label: 'Les investissements sous-jacents', component: TabComponent, min: 35, max: 37 },
    { id: '5', label: 'Risques liés à la distribution des contrats d\'assurance vie', component: TabComponent, min: 38, max: 42 },
    { id: '6', label: 'Paiements de prestations', component: TabComponent, min: 43, max: 50 },
    { id: '7', label: 'Divers', component: TabComponent, min: 51, max: 52 }
  ];

  secondRowTabs = [

  ];
  selectedTab = 0;
  contractDetail: any
  status: string
  policyRisks: any[]
  id: any
  changes: any[] = [];
  queryParams: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private clientsService: ClientsService,
    private dialog: MatDialog,
    private commonService: CommonService,
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.queryParams = params
    });
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
    })
    this.getContractDetail(this.id)
    this.getStatus(this.contractDetail?.status)
  }
  getContractDetail(id) {
    this.clientsService.getContractById(id).subscribe((res: any) => {
      this.contractDetail = res.body
      this.policyRisks = res.body.policyRisks
    })
  }
  handleItemChange(change: any) {
    const found = this.changes?.find((element) => element.id ===change.id);
    if (found) {
      let index = this.changes.indexOf(found);
      this.changes[index] = change;
    }else{
      this.changes.push(change);
    }


  }

  validate() {
    const message = "Etes vous sur de vouloir modifer les niveaux de risques?";
    const reponse = "Niveau(x) de risque(s) modifié(s) avec succès"
    const options = [
      { text: 'ok', value: true, color: 'accent', class: 'theme-bg-accent' },
      { text: 'Annuler', value: false, color: 'warn', class: 'theme-bg-accent' },
    ];
    const confirmPopUp = this.dialog.open(PopUpComponent, {
      data: { options, message }, panelClass: 'popupCofirmUpdateStatus'
    });
    confirmPopUp.afterClosed().subscribe((res) => {
      if (res) {
        this.clientsService.updateBulk(this.changes, this.id).subscribe((res: any) => {
          if (res) {
            this.commonService.showSnackBar(translate(reponse), this.commonService.successMessage);
            this.changes = [];
          }

        })
      }
    });

  }


  getStatus(value) {
    switch (value) {
      case "'IN_FORCE'":
        this.status = "En vigueur"
        break;
      case "'REDUCED'":
        this.status = "Réduit"
        break;
      case "'TERMINATED'":
        this.status = "résilié"
        break;
      case "'VALIDATION'":
        this.status = "en cours de validation"
        break;

      default:
        this.status = "En vigueur"
        break;
    }
  }


  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
  goToPanel(panel: any): void {
    this.selectedPanel = panel
    console.log(this.selectedPanel);

    // Close the drawer on 'over' mode
    if (this.drawerMode === 'over') {
      this.drawer.close();
    }
  }
  goBack() {
    this.router.navigate(['contracts'], { queryParams: this.queryParams });

  }

  getNumberRisksActived(panel) {
    const risks = this.policyRisks
      .filter(item => item.riskId >= panel.min && item.riskId <= panel.max && item.score > 0);
    return risks.length;
  }
}
