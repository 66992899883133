import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.scss']
})
export class PopUpComponent implements OnInit {

  options: any[];
  msg: any;
  product: any;
  status: any;
  familyClientCheckBeforeDeleteData: any;
  isIterable: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public currentDialog: MatDialogRef<PopUpComponent>,
  ) {
    this.options = this.data.options;
    this.msg = this.data.message;
    this.product = this.data.product;
    this.status = this.data.status;
  
    //check if is an array of messages 
    this.isIterable = typeof this.msg === "string" ? false : true
  }


  ngOnInit() {
  }

  action(value) {
    this.currentDialog.close(value);
  }

}
