import { HTTP_INTERCEPTORS, provideHttpClient } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig } from '@angular/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { PreloadAllModules, provideRouter, withInMemoryScrolling, withPreloading } from '@angular/router';
import { provideFuse } from '@fuse';
import { appRoutes } from 'app/app.routes';
import { provideAuth } from 'app/core/auth/auth.provider';
import { provideIcons } from 'app/core/icons/icons.provider';
import { provideTransloco } from 'app/core/transloco/transloco.provider';
import { mockApiServices } from 'app/mock-api';

import { KeycloakAngularModule, KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { StorageUtils } from './shared/storage/storage-utils';
import Keycloak from "keycloak-js";
import { AuthGuard } from './core/auth/guards/auth.guard';
import { HttpErrorInterceptor } from './shared/interceptors/http.interceptor';
import { CommonService } from './services/common/common.service';


function initializeKeycloak(keycloak: KeycloakService) {
    return () =>
        keycloak.init({
            config: {
                url: 'https://recette-mmc.by-lios.com/kc',
                realm: 'LIOS-REALM',
                clientId: 'kyc_local',
            },
            initOptions: {
                onLoad: 'login-required',
                checkLoginIframe: false,
                // silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html'
            },
        }).then((auth) => {

            if (!auth) {
                window.location.reload();
            } else {
                console.log(keycloak);
                const instance = keycloak.getKeycloakInstance();
                StorageUtils.setAccessToken(instance?.token)
                StorageUtils.setRefreshToken(instance?.refreshToken)
                
            }
            keycloak.keycloakEvents$.subscribe({
                next(event) {
                    if (event.type == KeycloakEventType.OnTokenExpired) {
                        keycloak.updateToken().then((refreshed)=>{
                            if (refreshed){
                                console.log('refreshed '+new Date());
                                const instance = keycloak.getKeycloakInstance();
                                StorageUtils.setAccessToken(instance?.token)
                                StorageUtils.setRefreshToken(instance?.refreshToken)
                            }else {
                                console.log('not refreshed '+new Date());
                            }
                        });

                    }
                }
            });
        });

}

export const appConfig: ApplicationConfig = {
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService]
        },
         KeycloakService,CommonService,
        provideAnimations(),
        provideHttpClient(),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
          },
        provideRouter(appRoutes,
            withPreloading(PreloadAllModules),
            withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
        ),

        // Material Date Adapter
        {
            provide: DateAdapter,
            useClass: LuxonDateAdapter,
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'D',
                },
                display: {
                    dateInput: 'DDD',
                    monthYearLabel: 'LLL yyyy',
                    dateA11yLabel: 'DD',
                    monthYearA11yLabel: 'LLLL yyyy',
                },
            },
        },

        // Transloco Config
        provideTransloco(),

        // Fuse
        // provideAuth(),
        provideIcons(),
        provideFuse({
            mockApi: {
                delay: 0,
                services: mockApiServices,
            },
            fuse: {
                layout: 'classic',
                scheme: 'light',
                screens: {
                    sm: '600px',
                    md: '960px',
                    lg: '1280px',
                    xl: '1440px',
                },
                theme: 'theme-brand',
                themes: [
                    {
                        id: 'theme-default',
                        name: 'Default',
                    },
                    {
                        id: 'theme-brand',
                        name: 'Brand',
                    },
                    {
                        id: 'theme-teal',
                        name: 'Teal',
                    },
                    {
                        id: 'theme-rose',
                        name: 'Rose',
                    },
                    {
                        id: 'theme-purple',
                        name: 'Purple',
                    },
                    {
                        id: 'theme-amber',
                        name: 'Amber',
                    },
                ],
            },
        }),
    ],
};
