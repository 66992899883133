/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'contracts',
        title: 'Contrats',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/contracts',
        moduleCodeName: 'contracts',
    },
    {
        id: 'controls-rules',
        title: 'Règles de contrôle',
        type: 'basic',
        icon: 'heroicons_outline:user-circle',
        link: '/controls-rules',
        moduleCodeName: 'controls-rules',
    },
    {
        id: 'countries',
        title: 'Table de codification',
        type: 'collapsable',
        icon: 'mat_outline:file_present',
        moduleCodeName: 'countries',
        children:[
            {
                id: 'countries',
                title: 'Pays',
                type: 'basic',
                link: '/countries',
                moduleCodeName: 'countries',
            },
            {
                id: 'socioProf',
                title: 'Catégories socio pro',
                type: 'basic',
                link: '/socio-professional-categories',
                moduleCodeName: 'socioProf',
            },
            {
                id: 'activityArea',
                title: 'Secteur d\'activité',
                type: 'basic',
                link: '/activity-areas',
                moduleCodeName: 'activityArea',
            },
        ]
    },
];
export const compactNavigation: FuseNavigationItem[] = defaultNavigation;
export const futuristicNavigation: FuseNavigationItem[] = defaultNavigation;
export const horizontalNavigation: FuseNavigationItem[] = defaultNavigation;
