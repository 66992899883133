<div class="flex flex-col w-full min-w-0 sm:absolute sm:inset-0 sm:overflow-hidden">
  <mat-card class="flex flex-col w-full" style="margin-bottom: 5px" class="card">
    <div class="flex flex-row w-full" style="font-weight: 600">
      <div>
        <button color="primary" mat-button (click)="goBack()">
          <mat-icon>arrow_back</mat-icon>
        </button>
      </div>

      <div class="form-field-group min-w-50 flex flex-col w-full">
        <div class="ml-2 lg:ml-0 text-3xl font-bold tracking-tight leading-none mb-4">Informations contrats</div>
        <span>Contrat :{{ contractDetail?.policyExt }}</span>
        <span>Date du contrat :{{ contractDetail?.effectDate | date : "dd/MM/YYYY" }}</span>

        <span>Statut : {{ status }}</span>
        <span>Score : {{ contractDetail?.score }}</span>
        <span>Niveau de vigilance : {{ contractDetail?.surveillanceLevel }}</span>
      </div>

      <div class="form-field-group min-w-50 flex flex-col w-full">
        <div class="ml-2 lg:ml-0 text-3xl font-bold tracking-tight leading-none mb-4">Informations tiers</div>
        <span *ngIf="contractDetail?.beneficiaries && contractDetail?.beneficiaries.length > 0"
          >Bénéficiaire :
          <span *ngFor="let item of contractDetail.beneficiaries"
            >{{ item?.name + " " + item?.firstName }}&nbsp;({{ item?.id | convertToEightPositions }})&nbsp;&nbsp;&nbsp;</span
          ></span
        >
        <span *ngIf="contractDetail?.network"
          >Apporteur :
          <span>{{ contractDetail?.network.name }}&nbsp;({{ contractDetail?.network?.originId | convertToEightPositions }})</span></span
        >
        <span *ngIf="contractDetail?.insureds && contractDetail?.insureds.length > 0">
          Assuré :
          <span *ngFor="let item of contractDetail.insureds"
            >{{ item.name + " " + item.firstName }}&nbsp;({{ item?.id | convertToEightPositions }})&nbsp;&nbsp;&nbsp;</span
          >
        </span>
        <span *ngIf="contractDetail?.subscribers && contractDetail?.subscribers.length > 0"
          >Souscripteur :
          <span *ngFor="let item of contractDetail?.subscribers"
            >{{ item.name + " " + item.firstName }}&nbsp;({{ item?.id | convertToEightPositions }})&nbsp;&nbsp;&nbsp;</span
          >
        </span>
      </div>
    </div>
  </mat-card>
  <mat-drawer-container class="flex-auto sm:h-full example-container">
    <!-- Drawer -->
    <mat-drawer class="sm:w-96 dark:bg-gray-900" [autoFocus]="false" mode="side" opened #drawer>
      <div class="flex items-center justify-between m-8 mr-6 sm:my-10">
        <!-- Title -->
        <div class="text-4xl font-extrabold tracking-tight leading-none"></div>
        <!-- Close button -->
        <div class="lg:hidden">
          <button mat-icon-button (click)="drawer.close()">
            <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
          </button>
        </div>
      </div>
      <!-- Panel links -->
      <div class="flex flex-col divide-y border-t border-b">
        <ng-container *ngFor="let panel of rowTabs">
          <div
            style="margin-bottom: 10px"
            class="flex px-8 py-5 cursor-pointer"
            [ngClass]="{
              'bg-primary-50 dark:bg-hover': selectedPanel && selectedPanel.id === panel.id,
              'hover:bg-gray-100 dark:hover:bg-hover': !selectedPanel || selectedPanel.id !== panel.id
            }"
            (click)="goToPanel(panel)"
          >
            <div class="flex flex-row justify-between w-full">
              <div class="font-medium leading-6">
                {{ panel.label }}
              </div>
              <div
                *ngIf="policyRisks && getNumberRisksActived(panel) > 0"
                class="px-2 bg-primary-300 text-black rounded-full"
                style="max-height: 24px; align-self: center"
              >
                {{ getNumberRisksActived(panel) }}
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </mat-drawer>

    <!-- Drawer content -->
    <mat-drawer-content class="flex flex-col" fuseScrollReset>
      <!-- Main -->
      <div class="flex-auto px-6 pt-9 pb-12 md:p-8 md:pb-12 lg:p-12 mb-40">
        <div class="flex items-center">
          <!-- Drawer toggle -->
          <button class="lg:hidden -ml-2" mat-icon-button (click)="drawer.toggle()">
            <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
          </button>

          <!-- Panel title -->
          <div *ngIf="selectedPanel" class="ml-2 lg:ml-0 text-3xl font-bold tracking-tight leading-none">
            {{ selectedPanel.label }}
          </div>
        </div>

        <!-- Load settings panel -->
        <div class="mt-8">
          <ng-container [ngSwitch]="selectedPanel.id">
            <!-- Notifications -->
            <ng-container *ngSwitchCase="'0'">
              <app-tab
                *ngIf="policyRisks"
                (tabItemChange)="handleItemChange($event)"
                [policyRisks]="policyRisks"
                [manuelRisks]="[]"
                [min]="selectedPanel.min"
                [max]="selectedPanel.max"
              ></app-tab>
            </ng-container>
            <ng-container *ngSwitchCase="'1'">
              <app-tab
                *ngIf="policyRisks"
                (tabItemChange)="handleItemChange($event)"
                [policyRisks]="policyRisks"
                [manuelRisks]="['I3', 'I5', 'I10', 'I12', 'I13', 'I17']"
                [min]="selectedPanel.min"
                [max]="selectedPanel.max"
              ></app-tab>
            </ng-container>
            <ng-container *ngSwitchCase="'2'">
              <app-tab
                *ngIf="policyRisks"
                (tabItemChange)="handleItemChange($event)"
                [manuelRisks]="['I24', 'I27']"
                [policyRisks]="policyRisks"
                [min]="selectedPanel.min"
                [max]="selectedPanel.max"
              ></app-tab>
            </ng-container>
            <ng-container *ngSwitchCase="'3'">
              <app-tab
                *ngIf="policyRisks"
                (tabItemChange)="handleItemChange($event)"
                [manuelRisks]="['I29', 'I30', 'I31', 'I33']"
                [policyRisks]="policyRisks"
                [min]="selectedPanel.min"
                [max]="selectedPanel.max"
              ></app-tab>
            </ng-container>
            <ng-container *ngSwitchCase="'4'">
              <app-tab
                *ngIf="policyRisks"
                (tabItemChange)="handleItemChange($event)"
                [policyRisks]="policyRisks"
                [manuelRisks]="['I35', 'I36', 'I37']"
                [min]="selectedPanel.min"
                [max]="selectedPanel.max"
              ></app-tab>
            </ng-container>
            <ng-container *ngSwitchCase="'5'">
              <app-tab
                *ngIf="policyRisks"
                (tabItemChange)="handleItemChange($event)"
                [manuelRisks]="['I40', 'I41', 'I42']"
                [policyRisks]="policyRisks"
                [min]="selectedPanel.min"
                [max]="selectedPanel.max"
              ></app-tab>
            </ng-container>
            <ng-container *ngSwitchCase="'6'">
              <app-tab
                *ngIf="policyRisks"
                (tabItemChange)="handleItemChange($event)"
                [manuelRisks]="['I45', 'I46', 'I50']"
                [policyRisks]="policyRisks"
                [min]="selectedPanel.min"
                [max]="selectedPanel.max"
              ></app-tab>
            </ng-container>
            <ng-container *ngSwitchCase="'7'">
              <app-tab
                *ngIf="policyRisks"
                (tabItemChange)="handleItemChange($event)"
                [policyRisks]="policyRisks"
                [manuelRisks]="['I51']"
                [min]="selectedPanel.min"
                [max]="selectedPanel.max"
              ></app-tab>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
  <div class="validateAndCancel">
    <button class="w-full" [color]="'primary'" mat-raised-button (click)="validate()">Valider</button>
  </div>
</div>
