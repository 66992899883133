import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertToEightPositions'
})
export class ConvertToEightPositionsPipe implements PipeTransform {
  transform(value: number): string {
    const numberInEightPositions = value.toString().padStart(8, '0');
    return numberInEightPositions;
  }
}
