<div class="flex flex-col">
  <div *ngFor="let item of risks" class="item-wrapper">
    <div style="min-width: 100%; position: relative; text-align: center">
      <div
        [ngClass]="{ 'text-blue-500': item.actived }"
        style="display: flex; justify-content: space-around; width: 100%; bottom: 0px; top: 40px"
      >
        <span style="font-weight: 600; text-align: left; align-self: center">{{ item.riskCode.toLowerCase() }} </span>
        <span [matTooltip]="item.riskLabel" style="font-weight: 600; text-align: left; align-self: center; width: 70%; min-width: 70%">
          {{ item.riskLabel }}
        </span>
        <div class="row">
          <mat-form-field style="max-width: 65px">
            <mat-select
              name="selectedScore"
              placeholder="Score"
              (selectionChange)="item.auto = false; onScoreChange(item)"
              [(ngModel)]="item.score"
            >
              <mat-option *ngFor="let i of scores" [value]="i.value">
                {{ i.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div style="width:48px">
          <mat-slide-toggle *ngIf="!item.manuel"
            (change)="item.auto = !item.auto; onScoreChange(item)"
            class="example-margin"
            [color]="'primary'"
            [checked]="item.manuel ? false : item.auto"
            [disabled]="disabled || item.score === null || item.manuel"
            style="margin-top: 15px"
          >
            Auto
          </mat-slide-toggle>
        </div>
      </div>
    </div>
  </div>
</div>
