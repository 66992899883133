import { AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Countries } from 'app/services/countries.service';
import { TableColumn } from './table-columns';
import { CommonService } from 'app/services/common/common.service';
import { translate, TranslocoService, TRANSLOCO_SCOPE } from '@ngneat/transloco';

@Component({
  selector: 'app-simple-data-table',
  templateUrl: './simple-data-table.component.html',
  styleUrls: ['./simple-data-table.component.scss'],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'datatable', alias: 'datatable' } }],

})
export class SimpleDataTableComponent implements OnInit, AfterViewInit {


  public tableDataSource = new MatTableDataSource<Element>([]);
  public displayedColumns: string[];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) matSort: MatSort;
  risks:any[] = []
  tooltipData:any
  rulesForm:FormGroup
  @Input() isPageable = false;
  @Input() size:number
  @Input() isSortable = false;
  @Input() tableColumns: TableColumn[];
  @Input() rowBalotAction: string;
  @Input() hasStatus: any;
  @Input() paginationSizes: number[] = [5, 10, 15,1000];
  @Input() defaultPageSize = this.paginationSizes[1];
  @Input() totalItems: number;
  @Input()  pageIndex: number = 0;
 
 
  toggleStatus: boolean = false;
  @Output() sort: EventEmitter<Sort> = new EventEmitter();
  @Output() rowAction: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  changPager: EventEmitter<any> = new EventEmitter();
  @Input() componentName:string
  dataArray:any[]
 
  // this property needs to have a setter, to dynamically get changes from parent component
  @Input() set tableData(data: any[]) {
    if(data && data.length>0){
      data.map(item=>{
        item.policyRisksTo= item?.policyRisks?.map(i=>i.riskCode+"-"+(i.riskLabel).split(' ').slice(0,4)+"...").join(', ')  
        this.toggleStatus = item?.cee      
      })
    }
    this.setTableDataSource(data);
  }

  btnRowClicked: any;

  constructor(
    private countriesService:Countries,
    private commonService:CommonService,
    private fb: FormBuilder,
    private _formBuilder: FormBuilder,
    private translocoService: TranslocoService,
        @Inject(TRANSLOCO_SCOPE) private scope
  ) {
  }

  ngOnInit(): void {
    const columnNames = this.tableColumns.map((tableColumn: TableColumn) => tableColumn.name);
    
    if (this.rowBalotAction) {
      this.displayedColumns = [...columnNames, this.rowBalotAction];
    } else {
      this.displayedColumns = columnNames;
    }
   
    
  }
  toggleCheckboxStatus(element) {    
    this.countriesService.updateInfo(element.id,element.cee,element.risky).subscribe((res:any)=>{
     if(res){
      this.commonService.showSnackBar("Modification russie",this.commonService.successMessage)
     }
      
    })
  }
  // we need this, in order to make pagination work with *ngIf
  ngAfterViewInit(): void {
    this.tableDataSource.paginator = this.paginator;
  }
  
  setTableDataSource(data: any) {
    this.tableDataSource = new MatTableDataSource<any>(data);
    this.tableDataSource.paginator = this.paginator;
    this.tableDataSource.sort = this.matSort;
    
  }

  sortTable(sortParameters: Sort) {
    // defining name of data property, to sort by, instead of column name
    sortParameters.active = this.tableColumns.find(column => column.name === sortParameters.active).dataKeyDto;
    this.sort.emit(sortParameters);
  }
 

  emitRowAction(action: any, r: any) {
    const act = {
      actionType: action,
      row: r
    };
    this.rowAction.emit(act);
  }

  showMoreBtn(element) { 
    this.btnRowClicked = element;
  }

  onFooterPage(event) {
    console.log(event);
    this.pageIndex = event.pageIndex;
    this.changPager.emit(
      event
    );

  }

}
