import { NgModule } from '@angular/core';
import { AsyncPipe, CommonModule, DatePipe, TitleCasePipe } from '@angular/common';
import { ClientsComponent } from './clients.component';
import { RouterModule, Routes } from '@angular/router';
import { ViewAllClientsComponent } from './view-all-clients/view-all-clients.component';
import { SharedModule } from 'app/shared/shared.module';
import { SimpleDataTableModule } from 'app/shared/components/simple-data-table/simple-data-table.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from 'app/shared/interceptors/http.interceptor';
import { MatExpansionModule } from '@angular/material/expansion';
import { ClientsDetailsComponent } from './clientsDetails/clientsDetails.component';

import { TabComponent } from './tab/tab.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonService } from 'app/services/common/common.service';
import { LegendComponent } from 'app/shared/components/legend/legend.component';
import { ConvertToEightPositionsPipe } from './convert-to-eight-positions.pipe';
import { ActivitiesComponent } from './audit/activities.component';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { ClientsService } from 'app/services/clients.service';
import { ActivitiesService } from './audit/activities.service';
import { RefreshComponent } from 'app/layout/common/refresh/refresh.component';



const routes: Routes = [
  {
    path: '',
    component: ViewAllClientsComponent
  },
  {
    path: ':id',
    children: [
      {
        path: '',
        redirectTo: 'details',
        pathMatch: 'full'
      },
      {
        path: 'details',
        component: ClientsDetailsComponent
      },
      {path: 'audit',
      component: ActivitiesComponent
      },
    
    ],
   
  },

];
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    SimpleDataTableModule,
    MatExpansionModule,
    AsyncPipe, 
    TitleCasePipe,
    DatePipe,
    FuseDrawerComponent,
    
   
  ],
  declarations: [
    ClientsComponent,
    ViewAllClientsComponent,
    ClientsDetailsComponent,
    ActivitiesComponent,
    TabComponent,
    ConvertToEightPositionsPipe,
    RefreshComponent
  ],
  exports:[RefreshComponent],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true
  },CommonService,ClientsService,ActivitiesService],
})
export class ClientsModule { }
