import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { Activity } from './activities.types';
import { environment } from 'environments/environment';

@Injectable({providedIn: 'root'})
export class ActivitiesService
{
    auditsUrl = environment.ENDPOINTS.AUDIT_URL;

    // Private
    private _activities: BehaviorSubject<any> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(private httpClient: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for activities
     */
    get activities(): Observable<any>
    {
        return this._activities.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------


    getAllAudit(id:number){
        const req = `${this.auditsUrl}/${id}/audit`;
        // const req ='https://run.mocky.io/v3/73bd4711-b339-47e6-993b-608edf30323f'
        return this.httpClient.get(req)
      }
}
