import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class Countries {


  countriesUrl = `${environment.ENDPOINTS.COUNTRIES_URL}`;


  constructor(private httpClient: HttpClient,) { }
  // api

  getAllCountries(page: number, size: number, sort?: any, direction?: any){  
    const req = `${this.countriesUrl}?size=${size}&page=${page}&sort=${sort},${direction}`;
    return this.httpClient.get<any[]>(req, { observe: 'response' });
  }

  updateInfo(id: number, cee: boolean, risky: boolean) {
    const reqUrl = `${this.countriesUrl}/${id}`;
    const requestBody = { id, cee, risky };
  
    return this.httpClient.patch(reqUrl, requestBody);
  }

}