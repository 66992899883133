import { CommonModule, NgIf } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClientsService } from 'app/services/clients.service';
import { CommonService } from 'app/services/common/common.service';
import { HttpErrorInterceptor } from 'app/shared/interceptors/http.interceptor';
import { SharedModule } from 'app/shared/shared.module';

@Component({
    selector       : 'refresh',
    templateUrl    : './refresh.component.html',
})
export class RefreshComponent implements OnInit, OnDestroy
{
    disabled = false;
    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private commonService:CommonService,
        private clientService: ClientsService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------





    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    refresh(){
        this.disabled = true
        this.clientService.refresh().subscribe(res=>{
            const msg = "Rafraîchissement lancé";
            this.commonService.showSnackBar(msg, this.commonService.successMessage);
            this.disabled = false;
        },err=>{
            this.disabled = false;
            this.commonService.showSnackBar("Un job en cours a déjà été lancé",this.commonService.errorMessage)
        }
        );
            
    
      }
}
