export const contents:any[] = [
  {
    title:"Risques liés au type de contrat / produit",
    content:{
      name: 'Tous',
      completed: false,
      color: 'primary',
      riskCodes:[
        'i1'
      ],
      subtasks: [
        {name: 'Type de contrat individuel (protection pure,epargne et investissement faible montant, epargne et investissement autre, contrat au porteur)',riskCode:'i1', completed: false, color: 'accent'},
       
      ],
    }
  },
  {
    title:"Risques liés au Client et son contrat",
    content:{
      name: 'Tous',
      completed: false,
      color: 'primary',

      subtasks: [
        {name: 'Pays de résidence du preneur',riskCode:'i2', completed: false, color: 'accent'},
        {name: 'Le preneur est une personne physique et est différent du bénéficiaire effectif',riskCode:'i3', completed: false, color: 'accent'},
        {name: 'Le preneur est une personne morale',riskCode:'i4', completed: false, color: 'accent'},
        {name: 'Pays de résidence du bénéficiaire effectif',riskCode:'i5', completed: false, color: 'accent'},
        {name: 'Pays de résidence de l\'assuré',riskCode:'i6', completed: false, color: 'accent'},
        {name: 'Nationalité du preneur',riskCode:'i7', completed: false, color: 'accent'},
        {name: 'Nationalité du bénéficiaire effectif ',riskCode:'i8', completed: false, color: 'accent'},
        {name: 'Nationalité de l\'assuré',riskCode:'i9', completed: false, color: 'accent'},
        {name: 'Le Client ou l\'assuré ont changé en cours de contrat',riskCode:'i10', completed: false, color: 'accent'},
        {name: 'Les documents probants établissant l\'identité du Client et/ou du bénéficiaire n\'ont pas été obtenus/régularisés',riskCode:'i11', completed: false, color: 'accent'},
        {name: 'Un montage spécial intervient dans le contrat Le contrat a fait l\'objet d\'une déclaration à la CRF du Parquet',riskCode:'i12', completed: false, color: 'accent'},
        {name: 'Le contrat a fait l\'objet d\'une déclaration à la CRF du Parquet',riskCode:'i13', completed: false, color: 'accent'},
        {name: 'Le Client ou une autre partie au contrat a été identifié comme figurant sur une liste de sanctions financières internationales',riskCode:'i14', completed: false, color: 'accent'},
        {name: 'Le Client ou son entourage ou une autre partie au contrat est classé comme PPE dans le sens de la Loi BC/FT',riskCode:'i15', completed: false, color: 'accent'},
        {name: 'Le Client exerce une profession sensible et/ou travaille dans un secteur d’activités sensibles',riskCode:'i16', completed: false, color: 'accent'},
        {name:'Les vérifications des antécédents ("background check") du Client ont fait apparaître d\'autres éléments de risque',riskCode:'i17',completed:false,color:'accent'},
        {name:'Le Client n\'a pas complété/répondu à toutes les exigences en matière de conformité fiscale',riskCode:'i18',completed:false,color:'accent'},
        {name:'Le Client a souscrit d\'autres contrats d\'assurance-vie avec les mêmes caractéristiques sans justification évidente',riskCode:'i19',completed:false,color:'accent'},
      ],
    }
  },
  {
    title:" La / les primes",
    content:{
      name: 'Tous',
      completed: false,
      color: 'primary',
      subtasks: [
        {name: 'L\'institution bancaire dont provient/doit provenir la première prime ou une prime subséquente est implantée au/dans un (( GDL, CEE, atres, risques élevés))',riskCode:'i20', completed: false, color: 'accent'},
        {name: 'L\'institution bancaire dont provient/doit provenir les primes est implantée dans un Etat autre que celui de la résidence du Client sans justification économique évidente ',riskCode:'i21', completed: false, color: 'accent'},
        {name: 'La prime est comprise entre 250.000 et 2.500.000 euros inclus',riskCode:'i22', completed: false, color: 'accent'},
        {name: 'La prime est supérieure à 2.500.000 euros',riskCode:'i23', completed: false, color: 'accent'},
        {name: 'La/les prime(s) ou le paiement est /sont payé(e)(s) en espèces, par chèque au porteur, par remise de titres ou valeurs au porteur', riskCode:'i24',completed: false, color: 'accent'},
        {name: 'Le contrat prévoit la possibilité de versements libres',riskCode:'i25', completed: false, color: 'accent'},
        {name: 'Les versements et leur périodicité ne correspondent pas aux renseignements fournis à la souscription (respectivement au cours de la vie) du contrat',riskCode:'i26', completed: false, color: 'accent'},
        {name: 'La/les prime(s) présente(nt) des élément atypiques',riskCode:'i27', completed: false, color: 'accent'},
       
      ],
    }
  },
  {
    title:"La clause bénéficiaire et les autres modalités du contrat",
    content:{
      name: 'Tous',
      completed: false,
      color: 'primary',
      subtasks: [
        {name: 'La clause bénéficiaire n\'est pas exclusivement établie en faveur de membres de la famille et/ou en faveur d’un établissement bancaire pour la garantie du remboursement d\'un prêt',riskCode:'i28', completed: false, color: 'accent'},
        {name: 'La clause bénéficiaire est établie en faveur d’une personne morale en dehors des circonstances visées à la question I 28 ou d\'une association de fait ou d\'une oeuvre de charité autre que les oeuvres fiscalement reconnues',riskCode:'i29', completed: false, color: 'accent'},
        {name: 'La clause bénéficiaire est établie en faveur d\'une personne physique si le contrat est souscrit par une personne morale ',riskCode:'i30', completed: false, color: 'accent'},
        {name: 'La clause bénéficiaire au profit d\'une personne autre que celles visées à la question I 28 ou au profit d\'une personne visée à la question I 29 a été acceptée par / rendue irrévocable',riskCode:'i31', completed: false, color: 'accent'},
        {name: 'Le contrat a été mis en garantie en faveur d\'une personne physique ou morale autre qu\'un établissement bancaire',riskCode:'i32', completed: false, color: 'accent'},
        {name: 'Le contrat est cédé ou ses droits sont transférés à un tiers sans lien ou justification plausible',riskCode:'i33', completed: false, color: 'accent'},
        {name: 'Le preneur d\'assurance a désigné une adresse de correspondance autre que son adresse de résidence',riskCode:'i34', completed: false, color: 'accent'},
      ],
    }
  },
  {
    title:"Les investissements sous-jacents",
    content:{
      name: 'Tous',
      completed: false,
      color: 'primary',
      subtasks: [
        {name: 'Le Client a demandé un investissement de sa prime en instruments non-cotés ',riskCode:'i35', completed: false, color: 'accent'},
        {name: 'Le Client a demandé un investissement de sa prime en instruments non-cotés (entre 10 et 50% de l\'émission des titres non cotés) ',riskCode:'i36', completed: false, color: 'accent'},
        {name: 'Le Client a un lien étroit avec, une influence ou un pouvoir de décision sur les sous-jacents non cotés',riskCode:'i37', completed: false, color: 'accent'},
       
      ],
    }
  },
  {
    title:"Risques liés à la distribution des contrats d'assurance-vie",
    content:{
      name: 'Tous',
      completed: false,
      color: 'primary',
      subtasks: [
        {name: 'La distribution du contrat a eu lieu par un intermédiaire établi dans un pays à risque élevé',riskCode:'i38', completed: false, color: 'accent'},
        {name: 'L\'intermédiaire réside dans un autre Etat (sauf GDL) que le Client',riskCode:'i39', completed: false, color: 'accent'},
        {name: 'L\'intermédiaire est autorisé à encaisser la/les prime(s) ou de payer les prestations du contrat',riskCode:'i40', completed: false, color: 'accent'},
        {name: 'L\'intermédiaire réside dans un autre Etat (sauf GDL) que le Client',riskCode:'i41', completed: false, color: 'accent'},
        {name: 'La distribution du contrat a eu lieu par le biais d\'internet ou d\'un autre moyen "à distance" ',riskCode:'i42', completed: false, color: 'accent'},
       
      ],
    }
  },
  {
    title:"Paiements de prestations",
    content:{
      name: 'Tous',
      completed: false,
      color: 'primary',
      subtasks: [
        {name: 'Le contrat a été résilié pendant le délai de renonciation',riskCode:'i43', completed: false, color: 'accent'},
        {name: 'Nombre de rachats des deux dernières années <=3 / > 3)',riskCode:'i44', completed: false, color: 'accent'},
        {name: 'Un paiement a entraîné des pénalités économiquement démesurées',riskCode:'i45', completed: false, color: 'accent'},
        {name: 'Le paiement a dû être fractionné et payé sur un nombre de comptes bancaires supérieur à celui de bénéficaires ou suit plusieurs rachats rapprochés avec paiements sur différents comptes',riskCode:'i46', completed: false, color: 'accent'},
        {name: 'L\'institution bancaire vers laquelle a été opéré le paiement d\'une prestation est implantée au/dans un ( GDL, CEE, autres, risques élevés)',riskCode:'i47', completed: false, color: 'accent'},
        {name: 'Il n\'y a pas de lien économique apparent entre la résidence du destinataire du paiement et l\'Etat d\'implantation de l\'institution bancaire vers laquelle le paiement a été effectué ou destiné (oui/non)',riskCode:'i48', completed: false, color: 'accent'},
        {name: 'Le changement de la clause bénéficiaire est exécuté dans les 6 mois précédents l\'échéance du contrat',riskCode:'i49', completed: false, color: 'accent'},
        {name: 'Un paiement a présenté un autre élément atypique ',riskCode:'i50', completed: false, color: 'accent'},
       
      ],
    }
  },
  {
    title:'Divers',
    content:{
      name: 'Tous',
      completed: false,
      color: 'primary',
      subtasks: [
        {name: 'Le dossier présente un autre élément atypique',riskCode:'i51', completed: false, color: 'accent'},
       
      ],
    }
  }
]
