<div class="customized-snackbar-container">
  <div [ngSwitch]="data.snackBarType" class="custmized-snackbar-icon">
    <div *ngSwitchCase="'success-message-snackbar'">

      <mat-icon class="material-icons">check_circle</mat-icon>

    </div>
    <div *ngSwitchCase="'error-snackbar'">

      <mat-icon class="material-icons">error</mat-icon>

    </div>
    <div *ngSwitchCase="'info-message-snackbar'">

      <mat-icon class="material-icons">info</mat-icon>

    </div>
  </div>
  <div class="custmized-snackbar-text">
    {{data.msg}}
  </div>
  <div (click)="closeSnackBar()" class="custmized-snackbar-close">
    <mat-icon>close</mat-icon>
  </div>
</div>