<div class="legend-container">
  <h3>Légende</h3>
  <div style="display: flex; justify-content: start;position: relative;height: 100px;">
    <div style="position: absolute;width: 50%;left: 0%;bottom: 2%;">
      <ul>
        <li >
          <div class="item-container">
            <span class="legend-item"> 
              <mat-icon class="icon-status" style="color: green;">circle</mat-icon>
            </span>
            <span style="font-weight: 600;">: Surveillance simplifiée</span>
          </div>
         
          </li>
          <li >
            <div class="item-container">
              <span class="legend-item"> 
                <mat-icon class="icon-status" style="color: orange;">circle</mat-icon>
              </span>
              <span style="font-weight: 600;">: Surveillance standard</span>
            </div>
           
            </li>
            <li >
              <div class="item-container">
                <span class="legend-item"> 
                  <mat-icon class="icon-status" style="color: rgb(192, 58, 5);">circle</mat-icon>
                </span>
                <span style="font-weight: 600;">: Surveillance renforcée</span>
              </div>
             
              </li>
              <li >
                <div class="item-container">
                  <span class="legend-item"> 
                    <mat-icon class="icon-status" style="color: #535353">circle</mat-icon>
                  </span>
                  <span style="font-weight: 600;">: Surveillance complémentaire</span>
                </div>
               
                </li>
        
      </ul>
    </div>
<!--   
    <div>
      <ul>
        <li >
          <div class="item-container">
            <span class="legend-item"> 
              <mat-icon class="icon-status"  style="color: red;">cancel</mat-icon>
            </span>
            <span style="font-weight: 600;">: Risqué</span>
          </div>
         
          </li>
        <li>
          <div class="item-container">
            <span class="legend-item"> 
              <mat-icon class="icon-status"  Style="color: green">check_circle</mat-icon>
            </span>
            <span style="font-weight: 600;">: Pas de risque</span>
          </div>
        </li>
        
      </ul>
    </div> -->
  </div>
  
</div>