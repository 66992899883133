// import jwt_decode from 'jwt-decode';

export class StorageUtils {

    static setAccessToken(token: any): void {
        localStorage.setItem('accessToken', token);
    }
    static getAccessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }
    static removeAccessToken(): void {
        localStorage.removeItem('accessToken');
    }

    static setRefreshToken(refresh: any): void {
        localStorage.setItem('refreshToken', refresh);
    }
    static getRefreshToken(): string {
        return localStorage.getItem('refreshToken') ?? '';
    }
    static removeRefreshToken(): void {
        localStorage.removeItem('refreshToken');
    }


    static setUser(user: any): void {
        localStorage.setItem('user', JSON.stringify(user));
    }
    static getUser(): any {
        const itemString = localStorage.getItem('user');
        if (itemString) {
            return JSON.parse(itemString);
        } else {
            return null;
        }
    }

    static getDecodedAccessToken(token: string): any {
        try {
            // return jwt_decode(token);
        } catch (error) {
            return null;
        }
    }

    static removeUser(): void {
        localStorage.removeItem('user');
    }

}
