<div fxLayout="column" *transloco="let datatable; read: 'datatable'">

  <!-- Table -->
  <mat-table  [dataSource]="tableDataSource" matSort (matSortChange)="sortTable($event)">

    <ng-container *ngIf="rowBalotAction?.length" [matColumnDef]="rowBalotAction">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element" [id]="rowBalotAction">
        <div >
          <button *ngFor="let act of element['actions']" mat-button [matMenuTriggerFor]="aboveMenu" (click)="$event.stopPropagation();act.onClick(element.id)">
            <mat-icon>history</mat-icon>
          </button>
          <!-- <mat-menu #aboveMenu="matMenu">
            <button mat-menu-item *ngFor="let act of element['actions']" (click)="act.onClick(element.id)">
              <mat-icon>{{act.icone}}</mat-icon> {{act.label}}
            </button>
          </mat-menu> -->
        </div>

      </mat-cell>
    </ng-container>

    <ng-container *ngFor="let tableColumn of tableColumns" [matColumnDef]="tableColumn.name">

      <!-- if sortable column header -->
      <ng-container *ngIf="tableColumn.isSortable; else notSortable">
        <mat-header-cell *matHeaderCellDef [mat-sort-header]="tableColumn.name"
          [arrowPosition]="tableColumn.position === 'right' ? 'before' : 'after'"
          [ngStyle]="{'min-width': (tableColumn.minWidth) + '%'}">
          {{datatable(tableColumn.name)}}
        </mat-header-cell>
      </ng-container>
      <!-- else not sortable -->
      <ng-template #notSortable>
        <mat-header-cell *matHeaderCellDef [class.text-right]="tableColumn.position == 'right'"
        [ngStyle]="{'min-width': (tableColumn.minWidth) + '%'}">
        {{datatable(tableColumn.name)}}
        </mat-header-cell>
      </ng-template>

     <!-- column data -->
     <mat-cell *matCellDef="let element" [class.text-right]="tableColumn.position == 'right'"
     [ngStyle]="{'min-width': (tableColumn.minWidth) + '%'}" [ngSwitch]="tableColumn.dataKey">

     <span *ngSwitchDefault class="text-overflow" [matTooltip]="element[tableColumn.dataKey]">
       {{element[tableColumn.dataKey]}}
     </span>

  
     <div *ngSwitchCase="'surveillanceLevel'" fxLayout="row" fxLayoutGap="8px" class="text-overflow margin-right-8">
       <mat-icon class="icon-status" *ngIf="element['surveillanceLevel']==='L1'"
         [matTooltip]="element.policyRisksTo" Style="color: green">circle</mat-icon>
       <mat-icon class="icon-status" *ngIf="element['surveillanceLevel']==='L2'"
         [matTooltip]="element.policyRisksTo" style="color: orange;">circle</mat-icon>
       <mat-icon class="icon-status" *ngIf="element['surveillanceLevel']==='L3'"
         [matTooltip]="element.policyRisksTo" Style="color: rgb(192, 58, 5)">circle</mat-icon>
         <mat-icon class="icon-status" *ngIf="element['surveillanceLevel']==='L4'"
         [matTooltip]="element.policyRisksTo" Style="color: #535353">circle</mat-icon>
     </div>
     <div *ngSwitchCase="'cee'" fxLayout="row" fxLayoutGap="8px" class="text-overflow margin-right-8">
      <!-- <mat-button-toggle [ngClass]="element.cee === true ? 'cee' : ' '" value="element.cee">
			</mat-button-toggle> -->
      <mat-slide-toggle [(ngModel)]="element.cee" (change)="toggleCheckboxStatus(element)"[color]="'primary'">
        
      </mat-slide-toggle>
     </div>
     <div *ngSwitchCase="'risk'" fxLayout="row" fxLayoutGap="8px" class="text-overflow margin-right-8">
      <!-- <mat-button-toggle [ngClass]="element.cee === true ? 'cee' : ' '" value="element.cee">
			</mat-button-toggle> -->
      <mat-slide-toggle [(ngModel)]="element.risky" (change)="toggleCheckboxStatus(element)" [color]="'primary'">
        
      </mat-slide-toggle>
     </div>


     <span *ngIf="tableColumn.dataKey==='fullName'" class="text-overflow"
       [matTooltip]="element[tableColumn.dataKey]">{{element[tableColumn.dataKey]}}</span>




     <div *ngSwitchCase="'risky'" fxLayout="row" fxLayoutGap="8px" class="text-overflow margin-right-8">
       <mat-icon class="icon-status" matTooltip="'Pas risqué'" *ngIf="element['risky'] === true" Style="color: green">check_circle</mat-icon>
       <mat-icon class="icon-status" matTooltip="'Risques liés au type de contrat / produit'"  *ngIf="element['risky'] === false" style="color: red;">cancel</mat-icon>
     </div>
     <span *ngIf="tableColumn.dataKey==='ServerName'" class="text-overflow"
       [matTooltip]="element[tableColumn.dataKeyDto] | translate">{{element[tableColumn.dataKeyDto] |
       translate}}</span>

     <span *ngSwitchCase="'hashInitial'" class="text-overflow" style="width: 80%;"
       [matTooltip]="element[tableColumn.dataKeyDto]">{{element[tableColumn.dataKeyDto]}}</span>

     <span *ngSwitchCase="'hashNow'" class="text-overflow" style="width: 80%;"
       [matTooltip]="element[tableColumn.dataKeyDto]">{{element[tableColumn.dataKeyDto]}}</span>
      
       
   </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row class="theme-bg-light-grey" *matRowDef="let row; columns: displayedColumns;"
      (click)="emitRowAction('rowClick',row)"></mat-row>
  </mat-table>
 

</div>
  <!-- Pagination -->
  <mat-paginator  *ngIf="isPageable && totalItems>10"
    class=" sm:inset-x-0 sm:bottom-0 border-b sm:border-t sm:border-b-0 z-10 bg-gray-50 dark:bg-transparent"
    [ngClass]="{'pointer-events-none': isLoading}"
    [length]="totalItems"
    [pageIndex]="pageIndex"
    [pageSize]="size"
    [pageSizeOptions]="[5, 10, 25, 50, 100]"
    (page)="onFooterPage($event)"
    [showFirstLastButtons]="false"></mat-paginator>

<!-- <app-legend class="mt-4 ml-4"></app-legend> -->
 