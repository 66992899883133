import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { contents } from "../view-all-clients/data";
import { ClientsService } from 'app/services/clients.service';
import { ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
})

export class TabComponent implements OnInit {
  @Input() min: number
  @Input() max: number
  color = 'accent';
  checked = false;
  disabled = false;
  data: any[]
  risks: any[]
  @Input() policyRisks
  @Output() tabItemChange: EventEmitter<any> = new EventEmitter<any>();
  changes: any[] = []; // Array to store the changes made in CardsItemComponent

  @Input() item;
  @Output() itemChange: EventEmitter<any> = new EventEmitter<any>(); // Emit changes made to the item
  value = 4;
  scores: any[] = [
    { id: 1, value: 0 },
    { id: 2, value: 1 },
    { id: 3, value: 2 },
    { id: 4, value: 3 },
    { id: 5, value: 4 },
  ];
  selectedScore = new FormControl(null);
  @Input() manuelRisks;
  constructor(
    private contractService: ClientsService,
    private router: ActivatedRoute
  ) { }

  ngOnInit() {
    this.data = contents;
    this.getContractDetail();
  }

  handleItemChange(change: any) {
    // Update the changes array when an itemChange event is emitted
    this.changes.push(change);
    this.tabItemChange.emit(change);
  }

  getContractDetail() {

    this.risks = this.policyRisks
      .filter(item => item.riskId >= this.min && item.riskId <= this.max)
      .sort((a, b) => a.riskId - b.riskId).map(item => ({
        ...item,
        actived: item.score > 0,
        manuel: this.manuelRisks.includes(item.riskCode)
      }));
      console.log(this.risks);
      
  }

  onScoreChange(item) {
    console.log(item);
    
    this.handleItemChange({ id: item.id, score: item.score, auto: item.auto });
  }

}
