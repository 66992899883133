import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss'],
    standalone : true,
    imports    : [RouterOutlet],

})
export class AppComponent
{
    /**
     * Constructor
     */
    constructor(private readonly keycloak: KeycloakService)
    {
    }

}
